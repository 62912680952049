const cards = [
   {
      id: "img01",
      target: "",
      alt: "Bacia Vietnamitas Bicolor",
      title: "Bacia Vietnamitas Bicolor",
      description: "",
   },
   {
      id: "img02",
      target: "",
      alt: "Vaso Vietnamita Florido",
      title: "Vaso Vietnamita Florido",
      description: "Diâmetro: 19cm e altura: 18cm",
   },
   {
      id: "img03",
      target: "",
      alt: "Vaso Vietnamita Ondulado",
      title: "Vaso Vietnamita Ondulado",
      description: "Diâmetro: 24cm e altura: 60cm",
   },
   {
      id: "img04",
      target: "",
      alt: "Vaso Vietnamita Quadrado Ondulado",
      title: "Vaso Vietnamita Quadrado Ondulado",
      description: "Diâmetro: 39cm e altura: 90cm",
   },
   {
      id: "img05",
      target: "",
      alt: "Vaso Vietnamita",
      title: "Vaso Vietnamita",
      description: "Diâmetro: 33cm e altura: 24cm",
   },
   {
      id: "img06",
      target: "",
      alt: "Vaso Vietnamita",
      title: "Vaso Vietnamita",
      description: "Diâmetro: 30cm e altura: 24cm",
   },
   {
      id: "img07",
      target: "",
      alt: "Vaso Vietnamita",
      title: "Vaso Vietnamita",
      description: "Diâmetro: 28cm e altura: 24cm",
   },
   {
      id: "img08",
      target: "",
      alt: "Vaso Vietnamita",
      title: "Vaso Vietnamita",
      description: "Diâmetro: 51cm e altura: 39cm",
   },
   {
      id: "img09",
      target: "",
      alt: "Vaso Vietnamita",
      title: "Vaso Vietnamita",
      description: "Diâmetro: 46cm e altura: 37cm",
   },
   {
      id: "img10",
      target: "",
      alt: "Vaso Vietnamita",
      title: "Vaso Vietnamita",
      description: "Diâmetro: 33cm e altura: 51cm",
   },
   {
      id: "img11",
      target: "",
      alt: "Vaso Vietnamita Ondulado",
      title: "Vaso Vietnamita Ondulado",
      description: "Diâmetro: 22cm e altura: 18cm",
   },
   {
      id: "img12",
      target: "",
      alt: "Vaso Vietnamita",
      title: "Vaso Vietnamita",
      description: "Diâmetro: 40cm e altura: 72cm",
   },
   {
      id: "img13",
      target: "",
      alt: "Vaso Vietnamita",
      title: "Vaso Vietnamita",
      description: "Diâmetro: 45cm e altura: 27cm",
   },
   {
      id: "img14",
      target: "",
      alt: "Vaso Vietnamita",
      title: "Vaso Vietnamita",
      description: "Diâmetro: 28cm e altura: 19cm",
   },
   {
      id: "img15",
      target: "",
      alt: "Vaso Vietnamita",
      title: "Vaso Vietnamita",
      description: "Diâmetro: 27cm e altura: 23cm",
   },
]

export { cards }